export function initPostMessageListeners() {
  window.addEventListener('message', (event) => {
    if (event && event.data && typeof event.data === 'object' && event.data.message != "" && 'isStaging' in event.data) {
      if(event.data.isStaging) {
        document.body.classList.add('display-staging');   
      }
      else {
        document.body.classList.remove('display-staging'); 
      }
    }
  }, false);
}